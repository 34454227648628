body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
  margin: 0;
  /* background-color: #e8f2ff; */
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

/*  Sample css from Formik playground */

code {
  background: #eee;
  padding: 0.1rem;
  font-family: 'Menlo';
  font-size: 13px;
  color: #ff00aa;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

button + button {
  margin-left: 0.5rem;
}

button.outline {
  background-color: #eee;
  border: 1px solid #aaa;
  color: #555;
}

.container {
  display: grid;
  grid-template-columns: [col1-start] 30% [col2-start] 50%;
  grid-template-rows:
    [ro1-start] 33%
    [row2-start] 33% [row3-start] 33%;
  justify-items: center;
}

.image1 {
  grid-column-start: col1-start;
  grid-column-end: 1;
  grid-row-start: ro1-start;
  grid-row-end: row3-start;
}
.image2 {
  grid-column-start: col1-start;
  grid-column-end: 1;
  grid-row-start: row3-start;
  grid-row-end: 3;
}
.card1 {
  grid-column-start: col2-start;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: row2-start;
  align-self: center;
}
.card2 {
  grid-column-start: col2-start;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: row3-start;
  align-self: center;
}
.card3 {
  grid-column-start: col2-start;
  grid-column-end: 2;
  grid-row-start: 3;
  align-self: center;
  grid-row-end: row3-start;
}

/* Media Queries for svg blobs */
/* @media (min-width: 56em) {
  .card1 .card2 .card3 {
    justify-self: flex-start;
  }
} */

